<template>
 <LoaderComp/>
</template>
<script>
// import axios from 'axios'
import LoaderComp from "../customize/component/LoaderComp.vue";
export default {
  components:{
   LoaderComp 
  },
  methods:{
    async checkCurrentUser(){
      const urlData=this.$route.query;
      if(urlData.page&&urlData.token)
      {
        localStorage.setItem("user_access_token",urlData.token)
        this.$router.push(urlData.page);
      }
      else
      {
        this.$router.push('/Error404');
      }
    },
    
  },
  created(){    
    if(Object.entries( this.$route.query).length !== 0 ){
     this.checkCurrentUser();
    }
  }
} 
</script>